import { useForm, FormProvider } from "react-hook-form"
import SelectField from "./form/SelectField"
import Widget from "./Widget"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import CheckboxField from "./form/CheckboxField"
import { useEffect, useState } from "react"
import Loading from "./Loading"
import axios from "axios"

import { useContext } from "react";
import TokenContext from "../utils/TokenContext";

const ServerSettings = () => {

  const methods = useForm()
  const {token} = useContext(TokenContext);
  const [ServerSetting, setServerSetting] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    axios.post(
      "/server_settings",
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then((res) => {
      setServerSetting(res.data);
      methods.reset(res.data)
    })
  }, [])

  const onSubmit = (data) => {

    if(isSubmitting) return;
    setIsSubmitting(true);

    axios.post(
      "/server_settings/update",
      {
        serverSetting: data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then((res) => {
      setServerSetting(res.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsSubmitting(false);
    })
  }

  if(!ServerSetting) {
    return <OwnWidget><Loading /></OwnWidget>
  }

  return <OwnWidget>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h3>Guild</h3>
        <SelectField name="activeGuildId" label="Active Guild Id" url="/guilds/list" rules={{required: "must be filled out"}}/>
        <hr />
        <h3>Channels</h3>
        <SelectField name="logChannelId" label="Server Log Channel ID" url="/channels/list" rules={{required: "must be filled out"}}/>
        <SelectField name="userLogChannelId" label="User Log Channel ID" url="/channels/list" rules={{required: "must be filled out"}}/>
        <SelectField name="welcomeChannelId" label="Welcome Message Channel ID" url="/channels/list" rules={{required: "must be filled out"}}/>
        <hr/>
        <h3>Roles</h3>
        <SelectField name="guestRoleId" label="Guest Role ID" url="/roles/list" rules={{required: "must be filled out"}}/>
        <SelectField name="communityRoleId" label="Community Role ID" url="/roles/list" rules={{required: "must be filled out"}}/>
        <hr />
        {false && <CheckboxField name="commandsEnabled" label="Enable Commands" />}
        <CheckboxField name="welcomeMessageEnabled" label="Enable Welcome Message" />
        <CheckboxField name="reactionRolesEnabled" label="Enable ReactionRoles" />
        <hr />
        <button className="btn btn-primary" disabled={isSubmitting}>
          { !isSubmitting ? <>
            <FontAwesomeIcon icon={faSave} className="me-2"/>
            Save ServerSettings
          </> : <>
            <FontAwesomeIcon icon={faSpinner} className="me-2" spin/>
            Submitting...
          </>}
        </button>
      </form>
    </FormProvider>
  </OwnWidget>
}

const OwnWidget = ({...props}) => {
  return <Widget header={"ServerSettings"}>
    {props.children}
  </Widget>
}

export default ServerSettings;