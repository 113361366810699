import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Login from "./components/Login";

import useJwtToken from "./utils/useJwtToken";
import TokenContext from "./utils/TokenContext";
import ReactionRoles from "./components/ReactionRoles";
import Messages from "./components/Messages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/messages",
    element: <Messages />
  },
  {
    path: "/reaction_roles",
    element: <ReactionRoles />
  },
]);

const App = () => {

  const { token, setToken } = useJwtToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return <TokenContext.Provider value={{
    token: token,
    setToken: setToken,
  }}><RouterProvider
    router={router}
    fallbackElement={<span>Loading</span>}
  /></TokenContext.Provider>
}



export default App;
