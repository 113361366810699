import { useForm } from "react-hook-form"
import classNames from "classnames";
import axios from "axios";

const Login = ({setToken}) => {

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    let response = await axios.post(
      "/login",
      data
    )

    console.log("LOGIN", data, response.data.errors)

    let errors = response?.data?.errors || [];

    if(response?.data?.status === "OK") {
      console.log("LOGIN OK", response.data.token)
      setToken(response.data.token);
      return;
    }

    for (let error of errors) {
      setError(error?.key, {
        type: error?.error?.type,
        message: error?.error?.message,
      })
    }
  }


  return <div className="container-fluid">
    <div className="row justify-content-center mt-3">
      <div className="col-11 col-md-10 col-lg-8 col-xl-6 col-xxl-5">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div></div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-floating mb-3">
                <input type="text" className={classNames("form-control", errors.username ? "is-invalid" : "")} placeholder="username" {...register("username", { required: "username is required" })} />
                <label>Username</label>
                {errors.username && <div className="invalid-feedback">{errors.username.message}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="password" className={classNames("form-control", errors.password ? "is-invalid" : "")} placeholder="password" {...register("password", { required: "password is required" })} />
                <label>Password</label>
                {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
              </div>
              <div className="text-center">
                <button className="btn btn-primary" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Login;