import { useState } from 'react';

export default function useJwtToken() {

  const getJwtToken = () => {
    return sessionStorage.getItem('afw_token');
  };

  const [token, setToken] = useState(getJwtToken());

  const saveJwtToken = token => {
    sessionStorage.setItem('afw_token', token);
    setToken(token);
  };

  return {
    setToken: saveJwtToken,
    token
  }
}
