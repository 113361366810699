import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import axios from "axios";
import { useContext } from "react";
import TokenContext from "../../utils/TokenContext";
import classNames from "classnames";

const SelectField = ({name, label, url, readonly, formatter, rules, ...props}) => {
  
  const { control } = useFormContext() 
  const {token} = useContext(TokenContext);
  const [collection, setCollection] = useState([])

  useEffect(() => {
    if(url) {
      axios.post(
        url,
        undefined,
        {
          headers: {
            'Authorization': "Bearer " + token
          }
        }
      ).then(response => {
        if(response.data) setCollection(response.data);
      })
    }
  }, [url, token])

  return <div className={classNames("form-floating", props.wrapperClassName, "mb-3")}><Controller
      name={name}
      control={control}
      rules={rules ||{}}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
        <>
          <select className={classNames("form-control", invalid ? "is-invalid" : "")} placeholder={label} ref={ref} onChange={onChange} value={value}>
            <option value="">{collection.length == 0 ? "Loading..." : ""}</option>
            {collection.map((i, index) => (formatter || formatOptionLabel)(i, name, index))}
          </select>
          <label>{label}</label>
          {invalid && <div className="invalid-feedback">{error.message}</div>}
        </>
      )}
    /></div>
}

const formatOptionLabel = ({ value, label}, name, index) => {
  return (
    <option key={"label_" + name + "_value" + value + "_index_" + index} value={value}>{label} | {value}</option>
  )
};

export default SelectField;