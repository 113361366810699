import classNames from "classnames";

const Widget = ({header, footer, ...props}) => {
  return <div className={classNames("card", props.cardClassNames)}>
    {header && <div className="card-header">{header}</div>}
    <div className={classNames("card-body", props.extraClassName)}>
      {props.children}
    </div>
    {footer && <div className="card-footer">{footer}</div>}
  </div>
}

export default Widget;