import { useEffect } from "react";
import CustomNavbar from "./CustomNavbar"
import Widget from "./Widget"
import { useState } from "react"
import { useContext } from "react";
import TokenContext from "../utils/TokenContext";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff, faEdit, faSave, faSpinner, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import classNames from "classnames";
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import TextField from "./form/TextField";
import SelectField from "./form/SelectField"

const ReactionRoles = () => {

  const [reactionRoleId, setReactionRoleId] = useState(undefined);
  const [rectionRoles, setReactionRoles] = useState([])
  const { token } = useContext(TokenContext);

  useEffect(() => {
    fetchReactionRoles()
  }, [])

  const fetchReactionRoles = () => {
    axios.post(
      "/reaction_roles",
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then((res) => {
      console.log(res.data);
      setReactionRoles(res.data);
    }).catch((err) => {
      console.error(err);
      setReactionRoles([]);
    })
  }

  return <>
    <CustomNavbar />
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-3">
          <List list={rectionRoles} set={setReactionRoles} select={setReactionRoleId} />
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <New callback={fetchReactionRoles}/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          { reactionRoleId && <Edit callback={fetchReactionRoles} reactionRoleId={reactionRoleId} rectionRoles={rectionRoles}/> }
          { reactionRoleId && <EditEmojiToRole reactionRoleId={reactionRoleId} rectionRoles={rectionRoles} callback={fetchReactionRoles}/> }
        </div>
      </div>
    </div>
  </>
}

const NewEmojiRole = ({reactionRoleId, callback}) => {
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const { token } = useContext(TokenContext);

  const onSubmit = (data) => {
    if(isSubmitting) return;
    setIsSubmitting(true);

    axios.post("/reaction_roles/roles/create", {
      id: reactionRoleId,
      data: data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      methods.reset({emojiId: "", roleId: ""});
      setIsSubmitting(false);
      callback();
    })
  }

  return <>
    <hr />
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col d-flex justify-content-start align-items-center">
            <SelectField name="emojiId" label="EmojiID" wrapperClassName="mb-md-0" url="/emojis/list" rules={{required: "must be filled out"}} />
          </div>
          <div className="col d-flex justify-content-start align-items-center">
          <SelectField name="roleId" label="RoleID" wrapperClassName="mb-md-0" url="/roles/list" rules={{required: "must be filled out"}} />
          </div>
          <div className="col-3 col-lg-2 d-flex justify-content-start align-items-center">
            <button className="btn btn-success w-100" type="submit">
              {isSubmitting? <><FontAwesomeIcon icon={faSpinner} className="me-2" spin={true} /> Creating...</> : <><FontAwesomeIcon icon={faPlus} className="me-2" />Create</>}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  </>
}

const EditEmojiToRole = ({reactionRoleId, rectionRoles, callback}) => {
  const reactionRole = rectionRoles.find((reactionRole) => reactionRole.id === reactionRoleId)

  if(!reactionRole) return;
  
  let config = reactionRole.config;

  let configKeys = Object.keys(config);

  return <Widget header={"Edit ReactionRole"} cardClassNames="mt-3">
    {configKeys.map((key, index) => {
      let value = config[key];

      return <EmojiRole key={"reaction_role_" + value + "_" + key + "_" + index} id={reactionRoleId} emojiId={key} roleId={value} callback={callback} />
    })}
    <NewEmojiRole callback={callback} reactionRoleId={reactionRoleId} />
  </Widget>
}

const EmojiRole = ({id, emojiId, roleId, callback}) => {
  
  const { token } = useContext(TokenContext);

  const deleteObj = () => {
    axios.post("/reaction_roles/roles/delete", {
      id: id,
      emojiId: emojiId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      callback()
    })
  }

  return <div className="row">
    <div className="col-2 col-lg-1"><img src={"https://cdn.discordapp.com/emojis/" + emojiId} className='img-fluid w-100 m-2' /></div>
    <div className="col d-flex justify-content-start align-items-center"><span className="badge bg-primary">{emojiId}</span></div>
    <div className="col d-flex justify-content-start align-items-center"><span className="badge bg-success">{roleId}</span></div>
    <div className="col-3 col-lg-2 d-flex justify-content-start align-items-center"><button onClick={deleteObj} className="btn btn-primary w-100" type="submit"><FontAwesomeIcon icon={faTrash} className="me-2"/>Delete</button></div>
  </div>
}

const Edit = ({callback, reactionRoleId, rectionRoles}) => {

  const reactionRole = rectionRoles.find((reactionRole) => reactionRole.id === reactionRoleId)
  const methods = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useContext(TokenContext);

  const onSubmit = (data) => {
    console.log(data)

    if(isSubmitting) return;
    setIsSubmitting(true);

    axios.post(
      "/reaction_roles/update",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }  
    ).then((res) => {
      callback();
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsSubmitting(false);
    })
  }

  useEffect(() => {
    if(reactionRole) {
      methods.reset(reactionRole);
    }
  }, [reactionRoleId])

  if(!reactionRole) return;

  return <Widget header={"Edit ReactionRole"}>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField name="messageId" label="MessageID" rules={{required: "must be filled out"}} />
        <SelectField name="channelId" label="ChannelID" url="/channels/list" rules={{required: "must be filled out"}} />
        <hr />
        <button className="btn btn-primary" disabled={isSubmitting}>
        { !isSubmitting ? <>
            <FontAwesomeIcon icon={faSave} className="me-2"/>
            EDIT
          </> : <>
            <FontAwesomeIcon icon={faSpinner} className="me-2" spin/>
            Submitting...
          </>}
        </button>
      </form>
    </FormProvider>
  </Widget>
}

const New = ({callback}) => {

  const methods = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useContext(TokenContext);

  const onSubmit = (data) => {
    console.log(data)

    if(isSubmitting) return;
    setIsSubmitting(true);

    axios.post(
      "/reaction_roles/create",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then((res) => {
      methods.reset({
        channelId: undefined,
        messageId: undefined
      })
      callback();
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      setIsSubmitting(false);
    })
  }

  return <Widget header={"Create new ReactionRole"}>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField name="messageId" label="MessageID" rules={{required: "must be filled out"}} />
        <SelectField name="channelId" label="ChannelID" url="/channels/list" rules={{required: "must be filled out"}} />
        <hr />
        <button className="btn btn-primary" disabled={isSubmitting}>
        { !isSubmitting ? <>
            <FontAwesomeIcon icon={faSave} className="me-2"/>
            Create
          </> : <>
            <FontAwesomeIcon icon={faSpinner} className="me-2" spin/>
            Submitting...
          </>}
        </button>
      </form>
    </FormProvider>
  </Widget>
}

const List = ({ list, select }) => {
  return <Widget header={"Table of ReactioRoles"} extraClassName={"p-1"}>
    <table className="table table-bordered table-striped mb-0">
      <thead>
        <tr>
          <th>Message</th>
          <th>Message ID</th>
          <th>Channel ID</th>
          <th className="text-center" style={{ width: "100px" }}>Active</th>
          <th className="text-center" style={{ width: "100px" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {list.length == 0 && <tr><td colSpan={3}>No elements found</td></tr>}
        {list.map((element, i) => <ListElement element={element} index={i} key={i} select={select} />)}
      </tbody>
    </table>
  </Widget>
}

const ListElement = ({ element, index, select, set }) => {
  return <tr>
    <td>
      {(element.messageContent || "").substring(0, 40)}
    </td>
    <td>{element.messageId}</td>
    <td>{element.channelId} | {element.channelName}</td>
    <td className={classNames("text-center", element.active ? "text-primary" : "")}>
      <FontAwesomeIcon icon={element.active ? faToggleOn : faToggleOff} className="me-2" size="2x" />
    </td>
    <td className="text-center">
      <span className="btn btn-primary btn-sm" onClick={() => select(element.id)}>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        Edit
      </span>
    </td>
  </tr>
}

export default ReactionRoles;