import { useContext } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import TokenContext from "../utils/TokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faDashboard, faLaughWink, faMessage } from "@fortawesome/free-solid-svg-icons";

import AustrianForceLogoSmall from "../../images/afw_main_logo_dark_bg.png";

const CustomNavbar = () => {

  const {setToken} = useContext(TokenContext);

  return <Navbar expand="lg">
    <Container fluid>
    <Navbar.Brand href="/"><img
            src={AustrianForceLogoSmall}
            alt="Austrian Force Willhaben Logo"
            height={40}
          /></Navbar.Brand>
    <Navbar.Toggle aria-controls="navigation" />
      <Navbar.Collapse id="navigation">
        <Nav className="me-auto">
          <NavLink className="nav-link" to="/">
            <FontAwesomeIcon icon={faDashboard} className="me-2" />
            Dashboard
          </NavLink>
          <NavLink className="nav-link" to="/reaction_roles">
            <FontAwesomeIcon icon={faLaughWink} className="me-2" />
            ReactionRoles
          </NavLink>
          <NavLink className="nav-link" to="/messages">
            <FontAwesomeIcon icon={faMessage} className="me-2" />
            Messages
          </NavLink>

        </Nav>
        <NavLink className="nav-link me-2" onClick={() => {
          setToken(undefined);
        }}>
          <FontAwesomeIcon icon={faSignOut} className="me-2" />
        </NavLink>

      </Navbar.Collapse>
    </Container>
  </Navbar>
}

export default CustomNavbar;