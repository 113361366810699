import { Controller, useFormContext } from "react-hook-form";

const CheckboxField = ({ name, label, readonly }) => {
  const { control } = useFormContext()

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
      <div className="form-check mb-3">
        <input className="form-check-input" type="checkbox" id={name} checked={value || false} onChange={onChange} ref={ref} />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          {label}
        </label>
        {invalid && <div className="invalid-feedback">{error.message}</div>}
      </div>)} />
}

export default CheckboxField;