import CustomNavbar from "./CustomNavbar"
import ServerSettings from "./ServerSettings"

const Dashboard = () => {

  return <>
    <CustomNavbar />
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 col-lg-6">
          <ServerSettings />
        </div>
      </div>
    </div>
  </>
}

export default Dashboard;