import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";

const TextField = ({ name, label, readonly, rules }) => {
  const { control } = useFormContext()

  return <Controller
    name={name}
    control={control}
    rules={rules || {}}
    render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
      <div className="form-floating mb-3">
        <input className={classNames("form-control", invalid ? "is-invalid" : "")} placeholder={label} type="text" id={name} value={value} onChange={onChange} ref={ref} />
        <label>{label}</label>
        {invalid && <div className="invalid-feedback">{error.message}</div>}
      </div>)} />
}

export default TextField;